export const Config = {
    network: {
        base: "https://starkiller.wtrg.io/api",
        api: {
            pretemplates: "/templates.php",
            amotemplates: "/amo_templates.php",
            cloud: "/cloud.php",
            numbers: "/numbers.php",
        },
        third: {
            qrGenerator: "https://qr.devtest.wtrg.io/generate.php",
        }
    },
    whatsapp: {
        numbers: {
            ceo: '74996476819'
        },
        plug: {
            image: "https://storage.yandexcloud.net/wtrg-media/static/PlugImage.png",
            video: "https://storage.yandexcloud.net/wtrg-media/static/PlugVideo.mp4",
            document: "https://storage.yandexcloud.net/wtrg-media/static/PlugDocument.pdf",
        }
    }
}