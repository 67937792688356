import {KiB, MiB} from "./Unit";
import {AssocLike} from "./Assoc";
import {queryMimes} from "./MimeHelper";

export const TEXT_MESSAGE_MAX_LENGTH = 4096; // Символов
export const TEMPLATE_NAME_MAX_LENGTH = 512; // Символов
export const BODY_MESSAGE_MAX_LENGTH = 1024; // Символов
export const FOOTER_MESSAGE_MAX_LENGTH = 60; // Символов
export const HEADER_MESSAGE_MAX_LENGTH = 60; // Символов
export const BUTTON_MESSAGE_MAX_LENGTH = 25; // Символов
export const NUMBERS_EMAIL_MAX_LENGTH = 128; // Символов
export const NUMBERS_SHORT_DESCRIPTION_MAX_LENGTH = 139; // Символов
export const NUMBERS_EXTENDED_DESCRIPTION_MAX_LENGTH = 256; // Символов
export const NUMBERS_ADDRESS_MAX_LENGTH = 256; // Символов
export const NUMBERS_WEBSITE_MAX_LENGTH = 256; // Символов

export const AUDIO_MAX_SIZE = 16 * MiB;
export const DOCUMENT_MAX_SIZE = 100 * MiB;
export const IMAGE_MAX_SIZE = 5 * MiB;
export const STICKER_MAX_SIZE = 100 * KiB;
export const VIDEO_MAX_SIZE = 16 * MiB;

export const VIDEO_MIME = queryMimes("video", "mp4", "3gp");
export const IMAGE_MIME = queryMimes("image", "jpeg", "png")
export const DOCUMENT_MIME = queryMimes("application", "pdf", "doc", "ppt", "xls", "docx", "pptx", "xlsx");

export type TemplateStatus = {[key: string]: TemplateStatusType};
export enum TemplateStatusType {
    PENDING,
    APPROVED,
    REJECTED,
    DISABLED
}

export const statusSurjection: AssocLike<TemplateStatusType> = {
    "PENDING": TemplateStatusType.PENDING,
    "APPROVED": TemplateStatusType.APPROVED,
    "REJECTED": TemplateStatusType.REJECTED,
    "approved": TemplateStatusType.APPROVED,
    "rejected": TemplateStatusType.REJECTED,
    "pending": TemplateStatusType.PENDING,
    "submitted": TemplateStatusType.PENDING,
    "moderate": TemplateStatusType.PENDING,
    "created": TemplateStatusType.APPROVED,
};

export type NotificationCategory = keyof typeof templateCategoriesInformation;
export const templateCategoriesInformation = {
    ACCOUNT_UPDATE: {deprecated: true, supported: true},
    ALERT_UPDATE: {deprecated: true, supported: true},
    APPOINTMENT_UPDATE: {deprecated: true, supported: true},
    AUTO_REPLY: {deprecated: true, supported: true},
    ISSUE_RESOLUTION: {deprecated: true, supported: true},
    PAYMENT_UPDATE: {deprecated: true, supported: true},
    PERSONAL_FINANCE_UPDATE: {deprecated: true, supported: true},
    SHIPPING_UPDATE: {deprecated: true, supported: true},
    RESERVATION_UPDATE: {deprecated: true, supported: true},
    TICKET_UPDATE: {deprecated: true, supported: true},
    TRANSPORTATION_UPDATE: {deprecated: true, supported: true},
    TRANSACTIONAL: {deprecated: true, supported: true},
    MARKETING: {deprecated: false, supported: true},
    OTP: {deprecated: true, supported: false},
    AUTHENTICATION: {deprecated: false, supported: false},
    UTILITY: {deprecated: false, supported: true},
};

export enum SupportedLanguage {
    RUSSIAN,
    ENGLISH_US,
    GERMAN,
    FRENCH,
    PORTUGUESE,
    SPANISH,
    ITALIAN,
    INDONESIAN,
    FILIPINO,
    HINDI,
    KAZAKH
}

export enum LanguageTag {
    RUSSIAN = "ru",
    ENGLISH_US = "en_US",
    GERMAN = "de",
    FRENCH = "fr",
    PORTUGUESE = "pt_PT",
    SPANISH = "es",
    ITALIAN = "it",
    INDONESIAN = "id",
    FILIPINO = "fil",
    HINDI = "hi",
    KAZAKH = "kk"
}

export enum HeaderTypes {
    NONE,
    TEXT,
    IMAGE,
    DOCUMENT,
    VIDEO,
}

export enum ButtonTypes {
    QUICK_REPLY,
    URL,
    PHONE_NUMBER
}